@import "bootstrap";

img { margin-bottom: 24px; 
     margin-top: 17px; 
    border-radius:2%;
}


body {
	padding-top: 70px;
}

figcaption {
    text-align: right;
    margin-top: -20px;
    margin-bottom: 20px;
}



/* Top navigation bar */


p.byline {
	text-align: center;
	font-size: 18px;
	line-height: 36px;
	margin-top: -24px;
	margin-bottom: 48px;
}


pubtit {
	font-weight: bold;
}

firstp {
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px; /* or 950px */
}

/* Home, News */
#newsid p {
	text-align: left;
}

#homeidtmp h1 {
	text-align: center;
}

#newsid img {
	margin-top: 6px;
	margin-bottom: 6px;
}

/* Team & Publications */
#gridid img { margin: 16px 22px 6px 0; border-radius:10%; box-shadow: 2px 2px 5px #888888 }
#homeid img { margin: 0 0 0 0; border-radius:5%; box-shadow: 0px 0px 0px #888888 }
#picid img { margin: 6px 3px 60px 6px; border-radius:1%; box-shadow: 2px 2px 5px #888888 }
#gridid .row { margin-bottom: 24px; }


/* Grid overrides */
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-5, .col-sm-6,
.col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11{
	padding-left: 16px;
	padding-right: 16px;
}

/* Grid overrides */
.col-sm-4 {
	padding-left: 26px;
	padding-right: 26px;
}

.col-sm-12 {
	padding-left: 16px;
	padding-right: 16px;
	// text-align: center;
}


/* Carusel */

.carousel {
    border-radius: 3px 3px 3px 3px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.col-sm-3.clearfix {
	details {
	  margin-bottom: 10px;
	  margin-left: -30px;
	  padding: 0;
	  
	  summary {
		cursor: pointer;
		padding: 5px 5px 5px 25px;
		position: relative;
		list-style: none;
		
		&::-webkit-details-marker {
		  display: none;
		}
		
		&::before {
		  content: '\25B6'; // Unicode for right-pointing triangle
		  position: absolute;
		  left: 5px;
		  top: 50%;
		  transform: translateY(-50%);
		  transition: transform 0.2s ease-in-out;
		  font-size: 0.8em;
		}
	  }
	  
	  &[open] {
		> summary::before {
		  transform: translateY(-50%) rotate(90deg);
		}
	  }
	}
	
	ul {
	  padding-left: 25px;
	}
  }

.full_collapse{
	details {
		margin-bottom: 10px;
		padding: 0;
		
		summary {
		cursor: pointer;
		padding: 5px 5px 5px 25px;
		position: relative;
		list-style: none;
		
		&::-webkit-details-marker {
			display: none;
		}
		
		&::before {
			content: '\25B6'; // Unicode for right-pointing triangle
			position: absolute;
			left: 5px;
			top: 50%;
			transform: translateY(-50%);
			transition: transform 0.2s ease-in-out;
			font-size: 0.8em;
		}
		}
		
		&[open] {
		> summary::before {
			transform: translateY(-50%) rotate(90deg);
		}
		}
	}
	
	ul {
		padding-left: 25px;
	}
}

.image-row {
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
  }
  
  .image-container {
	flex: 1;
	aspect-ratio: 1 / 1.25; /* This creates square containers. Adjust if needed */
	overflow: hidden;
	padding-right: 3px;
  }
  
  .image-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
  }

.scholar-img {
	border-radius: 0 !important;
	box-shadow: none !important;
	width: 7%;
	float: right;
	margin: 0px 10px 0px 0px;

}

.inline-underline {
	display: inline-block;
	border-bottom: 1px solid #000; /* Adjust color and thickness as needed */
  }

iframe { 
	width: 100%;
	aspect-ratio: 16 / 9;
  }